@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "rubik";
  src: url("../public/Rubik-Regular.ttf");
}

details > summary::-webkit-details-marker,
details > summary.list-none::marker {
  display: none;
}

body {
  margin: 0;
  font-family: "rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.5s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: black;
  transform: rotate(0);
  transition: all 0.6s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

#mobile-menu-wrapper {
  transition: all 0.6s;
}

.mobile-menu-open {
  transform: translateX(0px);
  /* transform: scale(1); */
}

.mobile-menu-close {
  transform: translateX(700px);
}

.embeddedForm * {
  font-family: "rubik" !important;
}

.carousel {
  display: inline-flex;
  overflow-x: hidden;
  /*  scroll snap is a great feature which will center the image on snap on touch screen devices  */
  scroll-snap-type: x mandatory;
  /* all below will hide the scrollbar on all browsers.    */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
